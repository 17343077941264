import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Accordion, useAccordion, Button, Card, Select, IconAngleDown, IconAngleUp } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const AccordionExample = () => {
  const initiallyOpen = false;
  const {
    isOpen,
    buttonProps,
    contentProps
  } = useAccordion({
    initiallyOpen
  });
  const icon = isOpen ? <IconAngleUp aria-hidden mdxType="IconAngleUp" /> : <IconAngleDown aria-hidden mdxType="IconAngleDown" />;
  return <>
      <Button iconLeft={icon} {...buttonProps} mdxType="Button">
        Advanced filters
      </Button>
      <Card border aria-label="Advanced filters" style={{
      marginTop: 'var(--spacing-m)'
    }} {...contentProps} mdxType="Card">
        <Select multiselect label="Filter by event category" placeholder="No selected categories" options={[{
        label: 'Culture & arts'
      }, {
        label: 'Sports'
      }, {
        label: 'Museums'
      }, {
        label: 'Music'
      }]} clearButtonAriaLabel="Clear all selections" selectedItemRemoveButtonAriaLabel="Remove" style={{
        maxWidth: '360px'
      }} mdxType="Select" />
        <Select multiselect label="Filter by event location" placeholder="No selected locations" options={[{
        label: 'Haaga'
      }, {
        label: 'Herttoniemi'
      }, {
        label: 'Kallio'
      }, {
        label: 'Kamppi'
      }, {
        label: 'Laajasalo'
      }, {
        label: 'Lauttasaari'
      }, {
        label: 'Mellunkylä'
      }, {
        label: 'Pasila'
      }]} clearButtonAriaLabel="Clear all selections" selectedItemRemoveButtonAriaLabel="Remove" style={{
        maxWidth: '360px',
        marginTop: 'var(--spacing-s)'
      }} mdxType="Select" />
      </Card>
    </>;
};
export const _frontmatter = {
  "slug": "/components/accordion",
  "title": "Accordion",
  "navTitle": "Accordion"
};
const layoutProps = {
  AccordionExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Accordion heading="How to publish data?" language="en" style={{
        maxWidth: '360px'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Use accordions to allow the user to quickly glance at the information and then choose to open sections that are interesting to them.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accordions must be given a header that describes the accordion content.`}</strong>{` This header also acts as the label for the expand button. The header level can be chosen depending on the structure of the page.`}</li>
      <li parentName="ul"><strong parentName="li">{`Do not put essential or must-read information inside accordions.`}</strong>{` If the user is expected to open all of the accordions while using the service, then it is likely that the information should not be inside accordions.`}
        <ul parentName="li">
          <li parentName="ul">{`Accordions work well when the user usually needs only part of the information. You may also consider placing parts of lower importance inside accordions while the most important parts are always visible.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`By default, accordions include a close button that is visible at the bottom of an expanded accordion. This is meant to allow the user to close the accordion quickly if needed.`}
        <ul parentName="li">
          <li parentName="ul">{`The close button can be hidden with the `}<inlineCode parentName="li">{`closeButton`}</inlineCode>{` property. While it is recommended to include the button, it can be left out in accordions with very little content.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Do not use accordions to create step-by-step forms. You must not expect that the user opens all accordions in a specific order.`}</li>
      <li parentName="ul">{`It is recommended to have accordions closed when the page is loaded.`}
        <ul parentName="li">
          <li parentName="ul">{`If you need to have the accordion initially open, you can use the `}<inlineCode parentName="li">{`initiallyOpen`}</inlineCode>{` property to achieve this.`}</li>
          <li parentName="ul">{`Having an accordion initially open could be needed e.g. when the user returns to a page and you want to retain accordions' states.`}</li>
          <li parentName="ul">{`Accordions should initially be closed when the page is loaded.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Expanding or closing the accordion should only affect the related accordion area. You also should not auto-close or auto-open accordions.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Basic HDS Accordions provide two different visuals to choose from. These implementations are easy to take into use if you do not need any special functionality or styling.`}</p>
    <p>{`This style is visually less distracting and it works well when there are multiple accordions one below another or nearby.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Accordion heading="How to publish data?" language="en" style={{
        maxWidth: '360px'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-a-card",
      "style": {
        "position": "relative"
      }
    }}>{`With a card`}<a parentName="h4" {...{
        "href": "#with-a-card",
        "aria-label": "with a card permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`You can also use HDS Cards as a container for your Accordions. Cards offer the same customizability options as default HDS Cards. To learn more about Cards and their customization, refer to `}<InternalLink href="/components/card" mdxType="InternalLink">{`HDS Card documentation.`}</InternalLink></p>
    <p>{`If you have multiple accordions one below another or many accordions in the same view, consider using the visually more light `}<a parentName="p" {...{
        "href": "#default"
      }}>{`default styling variant`}</a>{` instead of this one.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Accordion card border heading="How to publish data?" language="en" style={{
        maxWidth: '360px'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-the-close-button-hidden",
      "style": {
        "position": "relative"
      }
    }}>{`With the close button hidden`}<a parentName="h4" {...{
        "href": "#with-the-close-button-hidden",
        "aria-label": "with the close button hidden permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`In accordions that have very little content the close button does not offer much utility to the user. In these cases the button can be hidden with the `}<inlineCode parentName="p">{`closeButton`}</inlineCode>{` property.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Accordion heading="How to publish data?" language="en" style={{
        maxWidth: '360px'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>  
    </PlaygroundPreview>
    <h4 {...{
      "id": "accordion-size-variants",
      "style": {
        "position": "relative"
      }
    }}>{`Accordion size variants`}<a parentName="h4" {...{
        "href": "#accordion-size-variants",
        "aria-label": "accordion size variants permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Accordion includes three (3) size variants; small, default, and large. You can use different sizes depending on the screen size or use case. Use the `}<inlineCode parentName="p">{`size`}</inlineCode>{` property to alter the size.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Accordion size="s" card border heading="How to publish data?" language="en" style={{
        maxWidth: '360px'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
  <Accordion size="m" card border heading="How to publish data?" language="en" style={{
        maxWidth: '360px',
        marginTop: 'var(--spacing-s)'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
  <Accordion size="l" card border heading="How to publish data?" language="en" style={{
        maxWidth: '360px',
        marginTop: 'var(--spacing-s)'
      }} mdxType="Accordion">
    To publish your data, open your profile settings and click the button 'Publish'.
  </Accordion>
    </PlaygroundPreview>
    <h4 {...{
      "id": "custom-accordion",
      "style": {
        "position": "relative"
      }
    }}>{`Custom accordion`}<a parentName="h4" {...{
        "href": "#custom-accordion",
        "aria-label": "custom accordion permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If the basic accordion components do not fit your needs, you can build a custom accordion by using HDS provided accordion elements.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <AccordionExample mdxType="AccordionExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      